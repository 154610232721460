import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Events'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Events`}</h1>
    <p>{`A node will transition from one `}<a parentName="p" {...{
        "href": "./states"
      }}>{`state`}</a>{` to another when events
occur under certain conditions.`}</p>
    <p>{`If a node is a "root" node in a `}<a parentName="p" {...{
        "href": "./systems"
      }}>{`system`}</a>{`, it should listen
to external programatic changes to transition its state such as receiving the order
to transition in.`}</p>
    <p>{`If a node is not a root node, it is listening to its parent nodes to determine
when to change its state.`}</p>
    <p>{`An event in the node component such as mouse clicks, keyboard strokes, data loads,
or realtime notifications can trigger state transitions from one node to another,
to a particular sub-system, or even broadcast to the whole system.`}</p>
    <ul>
      <li parentName="ul">{`A link click event can make the entire system to transition out before changing
to a new application view.`}</li>
      <li parentName="ul">{`A form checkbox toggle event can make a sub-system in the system to transition
out before it is replaced for another.`}</li>
      <li parentName="ul">{`A menu hover event can make the menu children item nodes to transition in.`}</li>
    </ul>
    <p>{`Node components events should not necessarily change its own state. Such as toggling
a form checkbox or filling out a form input.`}</p>
    <p>{`For subtle interactions, like hovering a link or typing a key, subtle animations
and sounds could be played. These should not bring attention.`}</p>
    <p>{`For decisive interactions, like selecting an option or typing enter, more concise
animations and sounds could be played. These could bring attention.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      